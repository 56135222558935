import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { push, remove } from "../../../redux/features/opentabs";
import {
  CountryMaster, PageMaster, StateMaster, CityMaster,
  DepartmentMaster, EmployeeCategoryMaster, FinYearMaster, UserAndRolesMaster, PageGroupMaster,
  AccountSettings, ControlPanel, EmployeeMaster, TermsAndCondition,TrimFabricMaster
} from "..";

import {
  PartyCategoryMaster, CurrencyMaster, PartyMaster, ContentMaster, PortionMaster,
  YarnTypeMaster, CountsMaster, HsnMaster, YarnBlendMaster, YarnMaster,
  FabricTypeMaster, FabricMaster, AccessoryGroupMaster, AccessoryItemMaster,
  AccessoryMaster, ColorMaster, PurchaseOrder, TaxTermMaster, TaxTemplate,
  UnitOfMeasurementMaster, PayTermMaster, LocationMaster,
  GaugeMaster, LoopLengthMaster, GsmMaster, DiaMaster, DesignMaster,
  SizeMaster,
  PurchaseInwardReturn,
  PurchaseOrderCancel,
  PurchaseRegistry,
  StockRegistry,
  StyleMaster,
  ProcessMaster,
  ProcessDelivery,
  PurchaseInward,
  PurchaseReturn,
  YarnDeliveryToKnitting,
  SizeTemplateMaster,
  FabricDeliveryToProcess,
  ProcessInward,
  PrintFormatPurchaseOrder,
  CuttingOrder,
  CuttingDelivery,
  ProductionDelivery,
  CuttingReceipt,
  LossReasonMaster,
  DirectInward,
  BillEntry,
  PayOut,
  AdvanceAdjustment,
  PartyLedgerDetail,
  ProcessDeliveryReturn,
  ProcessBillEntry,
  CuttingExcessFabricReturn,
  ProductionReceipt,
  RawMaterialSales,
  FinishedGoodsSales,
  RawMaterialOpeningStock,
  FinishedGoodsOpeningStock,
  CuttingRegister,
  FinishedGoodsStockRegistry,
  FinishedGoodsSalesDelivery,
  PrintingJobWork,
  StockTransferFinishedGoods,
  PriceTemplateMaster,
  OnlineSalesDelivery,
  OnlineSalesReturn,
  CreditDebitNote,
  OpeningBalance,
  PurchaseLedgerDetail,
  SalesLedgerDetail,
  InfotermMaster,
  FieldMaster,
  ArticleMaster,
  ProcessGroupMaster,
  Costing,
  TemplateMaster,
  CostingApproval,
  CostingFinalQuote,
  RatioMaster,
  StyleFabricTemplate,

} from "../../../ERP/Components";

import { CLOSE_ICON, DOUBLE_NEXT_ICON } from "../../../icons";
import CompanyMaster from "../CompanyMaster";
import { useState } from "react";
import useOutsideClick from "../../../CustomHooks/handleOutsideClick";
import ProcessDeliveryRegister from "../../../ERP/Components/ProcessDeliveryRegister";

const ActiveTabList = () => {
  const openTabs = useSelector((state) => state.openTabs);
  const dispatch = useDispatch();
  const [showHidden, setShowHidden] = useState(false);

  const ref = useOutsideClick(() => { setShowHidden(false) })

  const tabs = {
    "PAGE MASTER": <PageMaster />,
    "COUNTRY MASTER": <CountryMaster />,
    "TRIM FABRIC MASTER" : <TrimFabricMaster />,
    "STATE MASTER": <StateMaster />,
    "INCOTERM MASTER": <InfotermMaster />,
    "CITY MASTER": <CityMaster />,
    "DEPARTMENT MASTER": <DepartmentMaster />,
    "EMPLOYEE CATEGORY MASTER": <EmployeeCategoryMaster />,
    "FIN YEAR MASTER": <FinYearMaster />,
    "USERS & ROLES": <UserAndRolesMaster />,
    "ACCOUNT SETTINGS": <AccountSettings />,
    "CONTROL PANEL": <ControlPanel />,
    "EMPLOYEE MASTER": <EmployeeMaster />,
    "COMPANY MASTER": <CompanyMaster />,
    "CUSTOMER CATEGORY MASTER": <PartyCategoryMaster />,
    "CURRENCY MASTER": <CurrencyMaster />,
    "CUSTOMER MASTER": <PartyMaster />,
    "CONTENT MASTER": <ContentMaster />,
    "PORTION MASTER": <PortionMaster />,

    "YARN TYPE MASTER": <YarnTypeMaster />,
    "COUNTS MASTER": <CountsMaster />,
    "HSN MASTER": <HsnMaster />,
    "YARN BLEND MASTER": <YarnBlendMaster />,
    "YARN MASTER": <YarnMaster />,
    "FABRIC TYPE MASTER": <FabricTypeMaster />,
    "FABRIC MASTER": <FabricMaster />,
    "PAGE GROUP MASTER": <PageGroupMaster />,
    "ACCESSORY GROUP MASTER": <AccessoryGroupMaster />,
    "ACCESSORY ITEM MASTER": <AccessoryItemMaster />,
    "ACCESSORY MASTER": <AccessoryMaster />,
    "COLOR MASTER": <ColorMaster />,
    "PURCHASE ORDER": <PurchaseOrder />,
    "UOM MASTER": <UnitOfMeasurementMaster />,
    "PAY TERM MASTER": <PayTermMaster />,
    "TAX TERM MASTER": <TaxTermMaster />,
    "TAX TEMPLATE": <TaxTemplate />,
    "GSM MASTER": <GsmMaster />,
    "GAUGE MASTER": <GaugeMaster />,
    "LOOP LENGTH MASTER": <LoopLengthMaster />,
    "DIA MASTER": <DiaMaster />,
    "DESIGN MASTER": <DesignMaster />,
    "SIZE MASTER": <SizeMaster />,
    "PURCHASE INWARD/RETURN": <PurchaseInwardReturn />,
    "PURCHASE ORDER CANCEL": <PurchaseOrderCancel />,
    "PURCHASE REGISTER": <PurchaseRegistry />,
    "STOCK REPORT": <StockRegistry />,
    "LOCATION MASTER": <LocationMaster />,
    "STYLE MASTER": <StyleMaster />,
    "PROCESS DELIVERY": <ProcessDelivery />,
    "PROCESS MASTER": <ProcessMaster />,
    "PROCESS GROUP MASTER": <ProcessGroupMaster />,
    "PURCHASE INWARD": <PurchaseInward />,
    "PURCHASE RETURN": <PurchaseReturn />,
    "YARN DELIVERY TO KNITTING": <YarnDeliveryToKnitting />,
    "SIZE TEMPLATE MASTER": <SizeTemplateMaster />,
    "FABRIC DELIVERY TO PROCESS": <FabricDeliveryToProcess />,
    "PROCESS INWARD": <ProcessInward />,
    "PRINT FORMAT PURCHASE ORDER": <PrintFormatPurchaseOrder />,
    "TERMS AND CONDITION": <TermsAndCondition />,
    "CUTTING ORDER": <CuttingOrder />,
    "CUTTING DELIVERY": <CuttingDelivery />,
    "CUTTING RECEIPT": <CuttingReceipt />,
    "LOSS REASON MASTER": <LossReasonMaster />,
    "DIRECT INWARD": <DirectInward />,
    "BILL ENTRY": <BillEntry />,
    "PAY OUT": <PayOut />,
    "ADVANCE ADJUSTMENT": <AdvanceAdjustment />,
    "PROCESS RETURN": <ProcessDeliveryReturn />,
    "PROCESS BILL ENTRY": <ProcessBillEntry />,
    "CUTTING EXCESS FABRIC RETURN": <CuttingExcessFabricReturn />,
    "PRODUCTION DELIVERY": <ProductionDelivery />,
    "PRODUCTION RECEIPT": <ProductionReceipt />,
    "RAW MATERIAL SALES": <RawMaterialSales />,
    "FINISHED GOODS SALES": <FinishedGoodsSales />,
    "PROCESS REGISTER": <ProcessDeliveryRegister />,
    "RAW MATERIAL OPENING STOCK": <RawMaterialOpeningStock />,
    "FINISHED GOODS OPENING STOCK": <FinishedGoodsOpeningStock />,
    "FINISHED GOODS STOCK": <FinishedGoodsStockRegistry />,
    "CUTTING REGISTER": <CuttingRegister />,
    "FINISHED GOODS SALES DELIVERY": <FinishedGoodsSalesDelivery />,
    "PRINTING JOB WORK": <PrintingJobWork />,
    "STOCK TRANSFER": <StockTransferFinishedGoods />,
    "PRICE TEMPLATE MASTER": <PriceTemplateMaster />,
    "ONLINE SALES DELIVERY": <OnlineSalesDelivery />,
    "ONLINE SALES RETURN": <OnlineSalesReturn />,
    "CREDIT DEBIT NOTE": <CreditDebitNote />,
    "OPENING BALANCE": <OpeningBalance />,
    "PARTY LEDGER": <PartyLedgerDetail />,
    "PURCHASE LEDGER": <PurchaseLedgerDetail />,
    "SALES LEDGER": <SalesLedgerDetail />,
    "ARTICLE MASTER": <ArticleMaster />,
    "COSTING": <Costing />,
    "FIELD MASTER": <FieldMaster />,
    "TEMPLATE MASTER": <TemplateMaster />,
    "COSTING APPROVAL": <CostingApproval />,
    "FINAL QUOTE": <CostingFinalQuote />,
    "RATIO MASTER": <RatioMaster />,
    "STYLE-FABRIC TEMPLATE": <StyleFabricTemplate />,
  };
  const innerWidth = window.innerWidth;
  const itemsToShow = innerWidth / 130;

  const currentShowingTabs = openTabs.tabs.slice(0, parseInt(itemsToShow));
  const hiddenTabs = openTabs.tabs.slice(parseInt(itemsToShow));
  return (
    <div className="relative h-full w-full">
      <div className="flex justify-between w-full">
        <div className="flex gap-2">
          {currentShowingTabs.map((tab, index) => (
            <div
              key={index}
              className={`p-1 rounded-t-md text-[10px] flex justify-center gap-1 ${tab.active ? "bg-red-300" : "bg-gray-300"
                }`}
            >
              <button
                onClick={() => {
                  dispatch(push({ name: tab.name }));
                }}
              >
                {tab.name}
              </button>
              <button className="hover:bg-red-400 px-1 rounded-xs transition"
                onClick={() => {
                  dispatch(remove({ name: tab.name }));
                }}
              >
                {CLOSE_ICON}
              </button>
            </div>
          ))}
        </div>
        <div>
          {(hiddenTabs.length !== 0) &&
            <button onClick={() => setShowHidden(true)}>
              {DOUBLE_NEXT_ICON}
            </button>
          }
        </div>
        {showHidden &&
          <ul ref={ref} className="absolute right-0 top-5 bg-gray-200 z-50 text-xs p-1">
            {hiddenTabs.map(tab =>
              <li key={tab.id} className={`flex justify-between hover:bg-blue-200  ${tab.active ? "bg-red-300" : "bg-gray-300"
                } `}>
                <button
                  onClick={() => {
                    dispatch(push({ id: tab.id }));
                  }}
                >
                  {tab.name}
                </button>
                <button className="hover:bg-red-400 px-1 rounded-xs transition"
                  onClick={() => {
                    dispatch(remove({ id: tab.id }));
                  }}
                >
                  {CLOSE_ICON}
                </button>
              </li>
            )}
          </ul>
        }
      </div>
      {openTabs.tabs.map((tab, index) => (
        <div key={index} className={`${tab.active ? "block" : "hidden"} h-[95%] w-full`}>
          {tabs[tab.name]}
        </div>
      ))}
    </div>
  );
};

export default ActiveTabList;
