import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  useGetPriceTemplateMasterQuery,
  useGetPriceTemplateMasterByIdQuery,
  useAddPriceTemplateMasterMutation,
  useUpdatePriceTemplateMasterMutation,
  useDeletePriceTemplateMasterMutation,
} from "../../../redux/ErpServices/PriceTemplateMasterServices";

import FormHeader from "../../../Basic/components/FormHeader";
import { toast } from "react-toastify";

import Modal from "../../../UiComponents/Modal";
import SearchReport from "./SearchReport";

import { getCommonParams, getDateFromDateTime, isGridDatasValid } from "../../../Utils/helper";
import ArticleDropdown from "../../ReusableComponents/ArticleDropdown";
import ProcessDropdown from "../../ReusableComponents/ProcessDropdown";
import { DateInput, DropdownInput, TextInput } from "../../../Inputs";

const MODEL = "Price Template";


export default function PriceTemplate() {
  const [readOnly, setReadOnly] = useState(false);
  const [id, setId] = useState("");
  const [formReport, setFormReport] = useState(false);
  const [processId, setProcessId] = useState('');
  const [articleId, setArticleId] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [effectiveDate, setEffectiveDate] = useState('');
  const [rate, setRate] = useState('');
  const [loss, setLoss] = useState('');

  const childRecord = useRef(0);

  const { branchId, companyId, finYearId, userId } = getCommonParams()

  const branchIdFromApi = useRef(branchId);
  const params = {
    branchId, companyId, finYearId
  };

  const { data: allData, isLoading, isFetching } = useGetPriceTemplateMasterQuery({ params });
  const {
    data: singleData,
    isFetching: isSingleFetching,
    isLoading: isSingleLoading,
  } = useGetPriceTemplateMasterByIdQuery(id, { skip: !id });

  const [addData] = useAddPriceTemplateMasterMutation();
  const [updateData] = useUpdatePriceTemplateMasterMutation();
  const [removeData] = useDeletePriceTemplateMasterMutation();

  const syncFormWithDb = useCallback((data) => {
    if (id) {
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
    setSelectedType(data?.Article?.selectedType || '')
    setProcessId(data?.processId || '');
    setArticleId(data?.articleId || '');
    setRate(data?.rate || 0);
    setLoss(data?.loss || 0);
    setEffectiveDate(getDateFromDateTime(data?.effectiveDate || new Date()))
    if (data?.branchId) {
      branchIdFromApi.current = data?.branchId
    }
  }, [id]);




  useEffect(() => {
    if (id) {
      syncFormWithDb(singleData?.data);
    } else {
      syncFormWithDb(undefined);
    }
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

  const data = {
    branchId, id, userId,
    articleId, processId, effectiveDate, rate, companyId, loss
  }

  const validateData = (data) => {
    return data.processId && data.articleId && data.effectiveDate && data.rate
  }

  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData;
      if (text === "Updated") {
        returnData = await callback(data).unwrap();
      } else {
        returnData = await callback(data).unwrap();
      }

      if (returnData.statusCode === 1) {
        toast.error(returnData.message);
        return
      }
      setId("")
      syncFormWithDb(undefined)
      toast.success(text + "Successfully");
    } catch (error) {
      console.log("handle");
    }
  };


  const saveData = () => {
    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", { position: "top-center" })
      return
    }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated");
    } else {
      handleSubmitCustom(addData, data, "Added");
    }
  }

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return;
      }
      try {
        await removeData(id)
        setId("");
        onNew();
        toast.success("Deleted Successfully");
      } catch (error) {
        toast.error("something went wrong");
      }
    }
  };

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "s") {
      event.preventDefault();
      saveData();
    }
  };

  const onNew = () => {
    setId("");
    setReadOnly(false);
    syncFormWithDb(undefined)
  };
  const Types = [
    { value: 'Yarn', show: "Yarn" },
    { value: 'Fabric', show: "Fabric" },
    { value: 'Trims', show: "Trims" },
  ]
  return (
    <div
      onKeyDown={handleKeyDown}
      className="md:items-start md:justify-items-center grid h-full bg-theme overflow-auto">
      <Modal isOpen={formReport} onClose={() => setFormReport(false)} widthClass={"px-2 h-[90%] w-[90%]"}>
        <SearchReport
          heading={MODEL}
          loading={
            isLoading || isFetching
          }
          tableWidth="100%"
          data={allData?.data}
          onClick={(id) => {
            setId(id);
            setFormReport(false);
          }
          }
        />
      </Modal>
      <div className="flex flex-col frame w-full h-full">
        <FormHeader
          onNew={onNew}
          model={MODEL}
          saveData={saveData}
          setReadOnly={setReadOnly}
          deleteData={deleteData}
          openReport={() => { setFormReport(true) }}
          childRecord={childRecord.current}
        />
        <div className="flex-1 grid gap-x-2">
          <div className="col-span-3 grid overflow-auto">
            <div className='col-span-3 grid overflow-auto'>
              <div className='mr-1'>
                <div className={`grid`}>
                  <div className={"flex flex-col"}>
                    <fieldset className='frame rounded-tr-lg rounded-bl-lg w-full border border-gray-600 px-3'>
                      <legend className='sub-heading'>Template Info</legend>
                      <div className='flex flex-col justify-center items-start flex-1 w-full'>
                        <div className="grid grid-cols-4 w-full">
                          <DropdownInput name={"Article Type"} value={selectedType}
                            setValue={setSelectedType}
                            options={Types} readOnly={readOnly} />
                          {selectedType &&
                            <>
                              <ArticleDropdown withSearch multiSelect={false} readOnly={readOnly} withoutLabel={false} userId={userId} name={selectedType} selected={articleId} setSelected={setArticleId} selectedType={selectedType} />
                              <ProcessDropdown withSearch multiSelect={false} readOnly={readOnly} withoutLabel={false} userId={userId} name={"Process"} selected={processId} setSelected={setProcessId} isFabric={(selectedType === "Fabric") || undefined} isYarn={(selectedType === "Yarn") || undefined} isTrims={(selectedType === "Trims") || undefined} />
                            </>
                          }
                          <DateInput name={"Effective Date"} value={effectiveDate} setValue={setEffectiveDate} readOnly={readOnly} />
                          <TextInput name={"Price"} value={rate} setValue={setRate} readOnly={readOnly} />
                          <TextInput name={"Loss"} value={loss} setValue={setLoss} readOnly={readOnly} />
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}