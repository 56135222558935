import React from 'react'
import { DropdownInput, DropdownWithSearch, MultiSelectDropdown } from '../../Inputs';
import { dropDownListObject, multiSelectOption } from '../../Utils/contructObject';
import { getCommonParams } from '../../Utils/helper';
import { useGetdesignQuery } from '../../redux/ErpServices/DesignMasterServices';

const DesignDropdown = ({ multiSelect = true, withoutLabel = true, readOnly, name, selected, setSelected, clear, withSearch }) => {
    const { token, ...params } = getCommonParams();
    const { data } = useGetdesignQuery({ params: { ...params } });

    if (withSearch)
        return (
            <div className='text-xs'>
                <label htmlFor="" >{name}</label>
                <DropdownWithSearch
                    value={selected}
                    setValue={(val) => setSelected(val)}
                    options={data?.data || []}
                    required
                    readOnly={readOnly} />
            </div>
        )
    return (
        <>
            {withoutLabel ?
                <select value={selected} onChange={(e) => setSelected(e.target.value)} className='w-full table-data-input'>
                    <option value="">Select</option>
                    {(data ? data.data : []).map(item =>
                        <option key={item.id} value={item.id}>{item.name}</option>
                    )}
                </select>
                :
                <>
                    {multiSelect ?
                        <MultiSelectDropdown readOnly={readOnly} name={name} selected={selected} setSelected={setSelected}
                            options={multiSelectOption(data ? data.data : [], "name", "id")} />
                        :
                        <DropdownInput readOnly={readOnly} name={name} value={selected} setValue={setSelected} clear={clear}
                            options={dropDownListObject(data ? data.data : [], "name", "id")} />
                    }
                </>
            }
        </>
    )
}

export default DesignDropdown
