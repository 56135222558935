import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  useGetStyleFabricTemplateQuery,
  useGetStyleFabricTemplateByIdQuery,
  useAddStyleFabricTemplateMutation,
  useUpdateStyleFabricTemplateMutation,
  useDeleteStyleFabricTemplateMutation,
} from "../../../redux/ErpServices/StyleFabricTemplateServices";
import FormHeader from "../../../Basic/components/FormHeader";
import { toast } from "react-toastify";

import Modal from "../../../UiComponents/Modal";
import SearchReport from "./SearchReport";

import { getCommonParams, isGridDatasValid } from "../../../Utils/helper";
import ProcessCostingSet from "./ProcessCosting";
import ArticleDropdown from "../../ReusableComponents/ArticleDropdown";
import ProcessDropdown from "../../ReusableComponents/ProcessDropdown";
import DesignDropdown from "../../ReusableComponents/DesignDropdown";
import { LongTextInputCustom, TextInput } from "../../../Inputs";
import { useGetArticleByIdQuery } from "../../../redux/ErpServices/ArticleService";
import { useGetdesignByIdQuery } from "../../../redux/ErpServices/DesignMasterServices";
import { useGetProcessByIdQuery, useGetProcessQuery } from "../../../redux/ErpServices/processMasterServices";
import { useGetUnitOfMeasurementMasterQuery } from "../../../redux/ErpServices/UnitOfMeasurementServices";

const MODEL = "Style-Fabric Template";


export default function StyleFabricTemplate() {
  const [readOnly, setReadOnly] = useState(false);
  const [id, setId] = useState("");
  const [formReport, setFormReport] = useState(false);
  const [styleId, setStyleId] = useState('');
  const [yarnId, setYarnId] = useState('');
  const [fabricId, setFabricId] = useState('');
  const [designId, setDesignId] = useState("");
  const [finishId, setFinishId] = useState('');
  const [specialFinishId, setSpecialFinishId] = useState('');
  const [yarnCostingDetails, setYarnCostingDetails] = useState([]);
  const [fabricCostingDetails, setFabricCostingDetails] = useState([]);
  const [accessoryCostingDetails, setAccessoryCostingDetails] = useState([]);
  const [cmtCostingDetails, setCmtCostingDetails] = useState([])
  const [gsm, setGsm] = useState('');
  const [name, setName] = useState('');
  const [refresh, setRefresh] = useState(true);

  const childRecord = useRef(0);

  const { branchId, companyId, finYearId, userId } = getCommonParams()

  const branchIdFromApi = useRef(branchId);
  const params = {
    branchId, companyId, finYearId
  };

  const { data: allData, isLoading, isFetching } = useGetStyleFabricTemplateQuery({ params });
  const {
    data: singleData,
    isFetching: isSingleFetching,
    isLoading: isSingleLoading,
  } = useGetStyleFabricTemplateByIdQuery(id, { skip: !id });

  const [addData] = useAddStyleFabricTemplateMutation();
  const [updateData] = useUpdateStyleFabricTemplateMutation();
  const [removeData] = useDeleteStyleFabricTemplateMutation();

  const syncFormWithDb = useCallback((data) => {
    if (id) {
      setReadOnly(true);
      setRefresh(false);
    } else {
      setReadOnly(false);
      setRefresh(true);
    }
    setStyleId(data?.styleId || '');
    setYarnId(data?.yarnId || '');
    setFabricId(data?.fabricId || '');
    setDesignId(data?.designId || "");
    setFinishId(data?.finishId || '');
    setGsm(data?.gsm || '');
    setSpecialFinishId(data?.specialFinishId || '');
    setFabricCostingDetails(data?.fabricCostingDetails || []);
    setYarnCostingDetails(data?.yarnCostingDetails || []);
    if (data?.branchId) {
      branchIdFromApi.current = data?.branchId
    }
  }, [id]);




  useEffect(() => {
    if (id) {
      syncFormWithDb(singleData?.data);
    } else {
      syncFormWithDb(undefined);
    }
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

  const data = {
    branchId, id, userId,
    fabricId, styleId, yarnCostingDetails, fabricCostingDetails, accessoryCostingDetails, cmtCostingDetails,
    yarnId, designId, finishId, specialFinishId, name, gsm
  }

  const { data: yarnData } = useGetArticleByIdQuery(yarnId, { skip: !yarnId });
  const { data: fabricData } = useGetArticleByIdQuery(fabricId, { skip: !fabricId });
  const { data: designData } = useGetdesignByIdQuery(designId, { skip: !designId });
  const { data: finishData } = useGetProcessByIdQuery(finishId, { skip: !finishId });
  const { data: specialFinishData } = useGetProcessByIdQuery(specialFinishId, { skip: !specialFinishId });
  const { data: processList } = useGetProcessQuery({})
  const { data: uomList } = useGetUnitOfMeasurementMasterQuery({})


  useEffect(() => {
    let name = '';
    if (yarnId && yarnData?.data?.name) {
      name += ' ' + yarnData?.data?.name;
    }
    if (fabricId && fabricData?.data?.name) {
      name += ' ' + fabricData?.data?.name;
    }
    if (designId && designData?.data?.name) {
      name += ' ' + designData?.data?.name;
    }
    if (finishId && finishData?.data?.name) {
      name += ' ' + finishData?.data?.name;
    }
    if (specialFinishId && specialFinishData?.data?.name) {
      name += ' ' + specialFinishData?.data?.name;
    }
    if (gsm) {
      name += ' ' + gsm;
    }
    setName(name);
  }, [yarnData, fabricData, designData, finishData, specialFinishData, yarnId, fabricId, designId, finishId, specialFinishId, gsm])


  useEffect(() => {
    if (!refresh) return
    if (!uomList?.data) return
    if (!processList?.data) return
    if (!yarnId) return
    setYarnCostingDetails(prev => {
      let uomId = uomList?.data.find(i => i.name === "KGS")?.id || '';
      const newProcessList = (processList?.data.filter(i => i?.isPurchase).map(i => ({ processId: i.id, articleId: yarnId, uomId, rate: i.rate, loss: i.loss })))
      const isProcessInNewList = (processId) => newProcessList.findIndex(i => parseInt(i.processId) === parseInt(processId)) !== -1;
      return [...prev.filter(i => !isProcessInNewList(i.processId)), ...newProcessList]
    })
  }, [processList, uomList, yarnId, id, refresh])



  useEffect(() => {
    if (!refresh) return
    if (!uomList?.data) return
    if (!processList?.data) return
    if (!fabricId) return
    setFabricCostingDetails(prev => {
      let uomId = uomList?.data.find(i => i.name === "KGS")?.id || ''
      const newProcessList = processList?.data.filter(i => i.isFabric && (i?.isKnitting || i?.isDefault)).map(i => ({ processId: i.id, uomId, rate: i.rate, loss: i.loss }))
      const isProcessInNewList = (processId) => newProcessList.findIndex(i => parseInt(i.processId) === parseInt(processId)) !== -1;
      return [...prev.filter(i => !isProcessInNewList(i.processId)), ...newProcessList]
    })
  }, [processList, uomList, fabricId, id, refresh])

  useEffect(() => {
    if (!refresh) return
    if (!uomList?.data) return
    if (!processList?.data) return
    if (!designId) return
    if (!designData?.data) return
    setFabricCostingDetails(prev => {
      let uomId = uomList?.data.find(i => i.name === "KGS")?.id || ''
      const newProcessList = processList?.data.filter(i => i?.isDyeing).map(i => ({ processId: i.id, uomId, rate: designData?.data?.rate, loss: designData?.data?.loss }))
      const isProcessInNewList = (processId) => newProcessList.findIndex(i => parseInt(i.processId) === parseInt(processId)) !== -1;
      return [...prev.filter(i => !isProcessInNewList(i.processId)), ...newProcessList]
    })
  }, [processList, uomList, designId, id, designData, refresh])

  useEffect(() => {
    if (!refresh) return
    if (!uomList?.data) return
    if (!processList?.data) return
    if (!finishId) return
    let finishObj = processList?.data.find(i => parseInt(i.id) === parseInt(finishId));
    const isProcessInNewList = (processId) => (processList?.data || []).filter(i => i?.isFinish).findIndex(i => parseInt(i.id) === parseInt(processId)) !== -1;
    setFabricCostingDetails(prev => {
      let uomId = uomList?.data?.find(i => i.name === "KGS")?.id || ''
      return [...prev.filter(i => !isProcessInNewList(i.processId)), { processId: finishId, uomId, rate: finishObj?.rate || 0, loss: finishObj?.loss || 0 }]
    })
  }, [processList, uomList, finishId, id, refresh])

  useEffect(() => {
    if (!refresh) return
    if (!uomList?.data) return
    if (!processList?.data) return
    if (!specialFinishId) return
    let specialFinishObj = processList?.data.find(i => parseInt(i.id) === parseInt(specialFinishId));
    const isProcessInNewList = (processId) => (processList?.data || []).filter(i => i?.isSpecialFinish).findIndex(i => parseInt(i.id) === parseInt(processId)) !== -1;
    setFabricCostingDetails(prev => {
      let uomId = uomList?.data.find(i => i.name === "KGS")?.id || ''
      return [...prev.filter(i => !(isProcessInNewList(i.processId))), { processId: specialFinishId, uomId, rate: specialFinishObj?.rate || 0, loss: specialFinishObj?.loss || 0 }]
    })
  }, [processList, uomList, specialFinishId, id, refresh])

  const validateData = (data) => {
    const mandatoryFields = ["processId"];
    return data.fabricId
      && isGridDatasValid(data.yarnCostingDetails, false, [...mandatoryFields, 'articleId', "uomId"])
      && isGridDatasValid(data.fabricCostingDetails, false, [...mandatoryFields, "uomId"])
  }

  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData;
      if (text === "Updated") {
        returnData = await callback(data).unwrap();
      } else {
        returnData = await callback(data).unwrap();
      }

      if (returnData.statusCode === 1) {
        toast.error(returnData.message);
        return
      }
      setId("")
      syncFormWithDb(undefined)
      toast.success(text + "Successfully");
    } catch (error) {
      console.log("handle");
    }
  };


  const saveData = () => {
    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", { position: "top-center" })
      return
    }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated");
    } else {
      handleSubmitCustom(addData, data, "Added");
    }
  }

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return;
      }
      try {
        await removeData(id)
        setId("");
        onNew();
        toast.success("Deleted Successfully");
      } catch (error) {
        toast.error("something went wrong");
      }
    }
  };

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "s") {
      event.preventDefault();
      saveData();
    }
  };

  const onNew = () => {
    setId("");
    setReadOnly(false);
    syncFormWithDb(undefined)
  };

  return (
    <div
      onKeyDown={handleKeyDown}
      className="md:items-start md:justify-items-center grid h-full bg-theme">
      <Modal isOpen={formReport} onClose={() => setFormReport(false)} widthClass={"px-2 h-[90%] w-[90%]"}>
        <SearchReport
          heading={MODEL}
          loading={
            isLoading || isFetching
          }
          tableWidth="100%"
          data={allData?.data}
          onClick={(id) => {
            setId(id);
            setFormReport(false);
          }
          }
        />
      </Modal>
      <div className="flex flex-col frame w-full h-full">
        <FormHeader
          onNew={onNew}
          model={MODEL}
          saveData={saveData}
          setReadOnly={setReadOnly}
          deleteData={deleteData}
          openReport={() => { setFormReport(true) }}
          childRecord={childRecord.current}
        />
        <div className="flex-1 grid gap-x-2">
          <div className="col-span-3 grid">
            <div className='col-span-3 grid'>
              <div className='mr-1'>
                <div className={`grid`}>
                  <div className={"flex flex-col"}>
                    <fieldset className='frame rounded-tr-lg rounded-bl-lg w-full border border-gray-600 px-3'>
                      <legend className='sub-heading'>Template Info</legend>
                      <div className='flex flex-col justify-center items-start flex-1 w-full'>
                        <div className="grid grid-cols-4 w-full">
                          <ArticleDropdown withSearch clear multiSelect={false} readOnly={readOnly} withoutLabel={false} userId={userId} name={"Yarn"} selected={yarnId} setSelected={setYarnId} selectedType={'Yarn'} />
                          <ArticleDropdown withSearch clear multiSelect={false} readOnly={readOnly} withoutLabel={false} userId={userId} name={"Fabric"} selected={fabricId} setSelected={setFabricId} selectedType={'Fabric'} />
                          <DesignDropdown withSearch clear multiSelect={false} readOnly={readOnly} withoutLabel={false} userId={userId} name={"Process"} selected={designId} setSelected={setDesignId} />
                          <ProcessDropdown withSearch clear multiSelect={false} readOnly={readOnly} withoutLabel={false} userId={userId} name={"Finish"} selected={finishId} setSelected={setFinishId} isFinish />
                          <ProcessDropdown withSearch clear multiSelect={false} readOnly={readOnly} withoutLabel={false} userId={userId} name={"Special Finish"} selected={specialFinishId} setSelected={setSpecialFinishId} isSpecialFinish />
                          <TextInput name={"GSM"} value={gsm} setValue={setGsm} />
                          <div className="col-span-2 flex items-center text-xs">
                            <LongTextInputCustom disabled className={'border-gray-500 border rounded input-field focus:outline-none'} name={"Style-Fabric"} value={name} />
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg my-1 w-full border border-gray-600 md:pb-5 h-full'>
                      <legend className='sub-heading'>Template Details</legend>
                      <div className='grid grid-cols-2 w-full gap-1'>
                        <ProcessCostingSet readOnly={readOnly} name={"Yarn"} costingDetails={yarnCostingDetails} setCostingDetails={setYarnCostingDetails} />
                        <ProcessCostingSet readOnly={readOnly} name={"Fabric"} costingDetails={fabricCostingDetails} setCostingDetails={setFabricCostingDetails} />
                        {/* <ProcessCostingSet readOnly={readOnly} name={"Trims"} costingDetails={accessoryCostingDetails} setCostingDetails={setAccessoryCostingDetails} /> */}
                        {/* <ProcessCostingSet readOnly={readOnly} name={"CMT"} costingDetails={cmtCostingDetails} setCostingDetails={setCmtCostingDetails} /> */}
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}